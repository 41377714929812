import React from "react";
import NorthstarPageLayout from "../../../components/NorthstarPageLayout";
import InsightsComponent from "../../../components/Insights";
import headerBackground from "../../../assets/northstar/header_bg.png"

interface InsightsPageProps {
    addBanner?: boolean
}

const Insights: React.FC<InsightsPageProps> = ({ addBanner = true }) => {
    return (
        <>
            <NorthstarPageLayout title="Insights">
                <>
                    {
                        addBanner &&
                            <div
                                className="mb-6 w-full h-36 rounded"
                                style={{
                                    backgroundImage: `url(${headerBackground})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "bottom"
                                }}
                            >
                                <div className="p-3">
                                    <div className="text-blue text-xl font-title">Insights</div>
                                    <div className="text-gray-dark text-xs md:text-sm">Insights provide access to curated content and playbooks from experts on our Data Science, Go-to-Market and Talent teams.</div>
                                </div>
                            </div>
                    }
                    <InsightsComponent showTitle={!addBanner}/>
                </>
            </NorthstarPageLayout>
        </>
    )
}

export default Insights;