import React from "react";
import { navigate } from "gatsby";
import docImage from "../../assets/northstar/leads.png"
import chartImage from "../../assets/northstar/chart.png"

interface TileComponentProps {
    title: string;
    tableauWorkbooks?: any;
    documents?: any;
    reports?: any;
    showTitle?: boolean;
}

const TileComponent: React.FC<TileComponentProps> = ({ title, tableauWorkbooks = [], documents = [], reports = [], showTitle = false }) => {

    const renderTableauWorkbooks = () => {
        return tableauWorkbooks.map(
            (workbook) => {
                const workbookName = encodeURIComponent(workbook.name);
                const workbookContentUrl = encodeURIComponent(workbook.contentUrl);
                const viewContentUrl = encodeURIComponent(workbook.view.urlName);

                return <div
                    className="border-gray-light border rounded mr-9 mb-8 bg-white cursor-pointer hover:border hover:border-orange" key={workbook.workbookId}
                    onClick={() => { navigate(`/northstar/${title.toLowerCase()}/view?workbookName=${workbookName}&workbookContentUrl=${workbookContentUrl}&viewContentUrl=${viewContentUrl}`) }}>
                    <div className="p-4">
                        {<img src={workbook.viewThumbnail} className="w-[300px] h-[167px] object-cover object-bottom"></img>}
                    </div>
                    <div className="p-4 h-[180px] w-[300px]">
                        <div className="font-bold">{workbook.name}</div>
                        {workbook.description !== "" ? <div className="line-clamp-3">{ workbook.description }</div> : <></> }
                        {
                            workbook.tags.length == 0 ? <></> : <div className="flex justify-left my-2 gap-1">
                            {
                                workbook.tags.map(
                                    (tag, i) => <button key={`${tag.label}-${i}`} className="px-3 py-1 no-underline rounded-full bg-orange text-white font-sans font-semibold text-xs border-blue mr-2">{ tag.label }</button>
                                )
                            }
                            </div>
                        }
                    </div>
                </div>
            }
        );
    }

    const renderDocuments = () => {
        const items = [];
        for (const doc of documents) {
            items.push(
                <div className=" border-gray-light border rounded mr-9 mb-8 bg-white cursor-pointer hover:border hover:border-orange" key={doc.id} onClick={() => { navigate(`/northstar/insights/publication?name=${doc.name}&id=${doc.id}`) }}>
                    <div className="p-4">
                        <img src={docImage} alt="img" className="w-[300px] h-[167px] object-scale-down"></img>
                    </div>
                    <div className="p-4 h-[180px] w-[300px]">
                        <div className="font-bold">{doc.name}</div>
                        {doc.description !== "" ? <div className="line-clamp-3">{ doc.description }</div> : <></> }
                        {
                            doc.tags.length == 0 ? <></> : <div className="flex justify-left my-2 gap-1">
                            {
                                doc.tags.map(
                                    (tag, i) => <button key={`${tag.label}-${i}`} className="px-3 py-1 no-underline rounded-full bg-orange text-white font-sans font-semibold text-xs border-blue mr-2">{ tag.name }</button>
                                )
                            }
                            </div>
                        }
                    </div>
                </div>
            )
        }
        return items;
    }

    const renderReports = () => {
        const items = [];
        for (const report of reports) {
            items.push(
                <div className=" border-gray-light border rounded mr-9 mb-8 bg-white cursor-pointer hover:border hover:border-orange" key={report.id} onClick={() => { navigate(`/northstar/tools/reports?id=${report.id}`) }}>
                    <div className="p-4">
                        <img src={chartImage} alt="img" className="w-[300px] h-[167px] object-cover object-bottom"></img>
                    </div>
                    <div className="p-4 h-[180px] w-[300px]">
                        <div className="font-bold">{report.name}</div>
                        {report.description !== "" ? <div className="line-clamp-3">{ report.description }</div> : <></> }
                        {
                            report.tags.length == 0 ? <></> : <div className="flex justify-left my-2 gap-1">
                            {
                                report.tags.map(
                                    (tag, i) => <button key={`${tag.label}-${i}`} className="px-3 py-1 no-underline rounded-full bg-orange text-white font-sans font-semibold text-xs border-blue mr-2">{ tag.name }</button>
                                )
                            }
                            </div>
                        }
                    </div>
                </div>
            )
        }
        return items;
    }

    return (
        <>
            {
                (!!tableauWorkbooks.length || !!reports.length) &&
                <>
                    {showTitle && <div className="font-bold pb-6">Tools</div>}
                    <div className="pb-6 flex flex-row flex-wrap flex-start">
                        {
                            renderTableauWorkbooks()
                        }
                        {
                            renderReports()
                        }
                    </div>
                </>
            }
            {
                !!documents.length &&
                <>
                    {showTitle && <div className="font-bold pb-6">Insights</div>}
                    <div className="pb-6 flex flex-row flex-wrap flex-start">
                        {
                            renderDocuments()
                        }
                    </div>
                </>
            }
        </>
    )
}

export default TileComponent
